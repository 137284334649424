@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this CSS to your main CSS file or include it in a style tag in your component file */
.typewriter {
    display: inline-block;
}
  
.cursor {
    display: inline-block;
    width: 2px;
    background-color: white;
    animation: blink 1s infinite;
}
  
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
